<template>
  <div class="newsindex">
    <!-- <div class="topbg"></div> -->
    <div class="carousel">
      <el-carousel :height="isPc ? '357px' : '47.6vw'" style="z-index: 10" indicator-position="none">
        <el-carousel-item v-for="(item, index) in shufflingList" :key="index">
          <div :style="`width: 100%; height: ${ isPc ? '357px' : '47.6vw' }`">
            <img style="width: 100%; height: 100%" :src="item.adImg" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="navbar" v-if="detailList">当前位置：<span class="navtitle" @click="goIndex">首页</span> > <span
        class="articleName">{{
          detailList.articleName
        }}</span></div>
    <section class="container" v-loading="vloading">
      <el-container>
        <el-aside width="240px">
          <el-menu ref="menus" :default-active="activeIndex" :default-openeds="['1']" class="el-menu-vertical-demo"
            :unique-opened="true" @close="handleClose">
            <template>
              <el-submenu index="1">
                <template slot="title"><span @click="navFirstClick">{{ navTitle }}</span></template>
                <el-menu-item v-for="(item, index) in subMenu" :index="item.typeId" :key="index"
                  @click="selectAritcleByTypeId(item.typeId)">
                  {{ item.typeName }}
                </el-menu-item>
              </el-submenu>
            </template>
            <!-- <el-submenu :index="item.menuId" v-for="(item, index) in nav" :key="item.menuId">
              <template slot="title">
                <span slot="title"
                  @click="menuShow(index + 1, item.navigationType, item.moduleCode, item.menuId)">{{ item.menuName }}</span>
              </template>
              <el-menu-item-group v-for="(ii, index) in item.subMenu" :key="index">
                <el-menu-item :index="ii.typeId"
                  @click="selectAritcleByTypeId(ii.typeId)">{{ ii.typeName }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu> -->
          </el-menu>
        </el-aside>
        <el-main v-if="!detailList">
          <div class="allist mb15">
            <div class="shadow radius5 bgwhite pad10">
              <div v-if="newList.length != 0">
                <el-row>
                  <el-col v-for="(item, index) in newList" :key="index" :span="24" class="padding20 flexstart borderbot"
                    style="display: flex;align-items: center;margin-top:20px;padding-bottom:20px;border-bottom: 1px dotted #cccccc;">
                    <i class="i_i"></i>
                    <span style="text-decoration: none;cursor: pointer;flex:1;    color: #515151;"
                      @click="getArticleInfo(item)">{{ item.article_name }}</span>
                    <div>{{ item.createdAt.slice(0, 10) }}</div>

                  </el-col>
                </el-row>
              </div>
              <div v-else>
                <el-empty :image-size="200" description="暂无文章"></el-empty>
              </div>
              <div class="page mt15">
                <el-pagination background layout="prev, pager, next" :page-size="pageSize" :total="count"
                  :current-page="page" @current-change="handleCurrentChange">
                </el-pagination>
              </div>
            </div>
          </div>
        </el-main>
        <el-main v-else>
          <div class="allists mb50">
            <div class="shadow radius5 bgwhite pad10">
              <h2>{{ detailList.articleName }}</h2>
              <div class="release-info borderbot">
                <span><b>发布人：</b>{{ detailList.authorName }}</span>
                <el-divider direction="vertical"></el-divider>
                <span><b>阅读量：</b>{{ detailList.checknum }}</span>
              </div>
              <div class="newscont pad20">
                <p v-html="detailList.content"></p>
              </div>
              <div class="pd20 cor4">
                <span><i class="el-icon-time"></i><b style="font-weight: 400">发布时间：</b>
                  {{ detailList.createdat.slice(0, 10) }}
                </span>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </section>

    <!-- <div style="mt15"> -->
    <!-- <ul class="flex pad15 newsty bgwhite">
        <b>分类:</b>
        <li class="newli" :class="{ active: newIndex == 0 }" @click="tabClick(0, 1)">
          全部
        </li>
        <li class="newli" v-for="(tab, index) in stateTab" :key="index" :class="{ active: newIndex == index + 1 }"
          @click="tabClick(index + 1, tab.typeId)">
          {{ tab.typeName }}
        </li>
      </ul> -->
    <!-- <el-tabs v-model="curTab" @tab-click="tabClick">
        <el-tab-pane label="全部" name="typeId"/>
        <el-tab-pane
          v-for="(tab, index) in stateTab"
          :key="index"
          :label="tab.typeName"
          :name="tab.typeId"
        />
      </el-tabs> -->
    <!-- </div> -->

  </div>
</template>

<script>
export default {
  data() {
    return {
      token: localStorage.getItem("pc_token"),
      stateTab: [],
      newList: [],
      activeName: "typeId",
      curTab: "",
      pageSize: 5,
      count: 0,
      newIndex: 0,
      page: 1,
      tabName: "",
      navTitle: "",
      subMenu: [],
      activeIndex: '',
      detailList: null,
      vloading: true,
      shufflingList: []
    };
  },
  mounted() {
    this.$api
      .selectContentList({
        channelId: this.$http.channelId,
        token: this.token,
        codes: "000001",
      })
      .then((res) => {
        if (res.code == 10000) {
          this.shufflingList = res.data;
        }
      });
    if ('subId' in this.$route.query) {
      this.selectAritcleByTypeId(this.$route.query.subId)
    } else {
      if ('typeId' in this.$route.query) {
        this.selectAritcleByTypeId(this.$route.query.typeId)
      } else {
        if ('id' in this.$route.query) {
          this.selectAritcleByTypeId(this.$route.query.id)
        } else if ('aId' in this.$route.query) {
          this.getArticleInfo({ article_id: this.$route.query.aId })
          this.getArticleTypeByParentApi(this.$route.query.atId)
          this.selectAritcleByTypeId(this.$route.query.atId)
          this.navTitle = this.$route.query.tName
        } else {
          this.selectAritcleByTypeId('')
        }

      }
    }
    if ('name' in this.$route.query) {
      this.navTitle = this.$route.query.name
      this.getArticleTypeByParentApi(this.$route.query.typeId);
    }

  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        // console.log('路由变化了')
        console.log('当前页面路由333：', to);
        console.log('上一个路由444：', from);
        if ('subId' in to.query) {
          this.getAppNavigationMenuApi()
          this.selectAritcleByTypeId(to.query.subId)
        } else {
          if ('typeId' in this.$route.query) {
            this.selectAritcleByTypeId(this.$route.query.typeId)
          } else {
            this.getAppNavigationMenuApi()
            if ('id' in this.$route.query) {
              this.selectAritcleByTypeId(this.$route.query.id)
            } else {
              this.selectAritcleByTypeId('')
            }

          }
        }
      }
    }
  },
  computed: {
    isPc() {
			return window.screen.width <= 767 ? false : true;
		},
  },
  methods: {
    navFirstClick() {
      if ('typeId' in this.$route.query) {
        this.selectAritcleByTypeId(this.$route.query.typeId)
      } else {
        if ('id' in this.$route.query) {
          this.selectAritcleByTypeId(this.$route.query.id)
        } else {
          if ('aId' in this.$route.query) {
            this.selectAritcleByTypeId(this.$route.query.atId)
          } else {
            this.selectAritcleByTypeId('')
          }

        }
      }
    },
    handleClose(key, keyPath) {
      this.$refs.menus.open(keyPath);
    },
    menuShow(index, type, code, menuId) {
      this.menuIndex = index;
      if (type == 1 && code == "1") {
        this.$router.push({
          path: "/news/index",
          query: { id: menuId }
        });
      } else if (type == 1 && code == "3") {
        this.$router.push({
          path: "/course/index",
          query: { id: menuId }
        });
      } else if (type == 1 && code == "4") {
        this.$router.push({
          path: "/live/index",
          query: { id: menuId }
        });
      } else if (type == 1 && code == "2") {
        this.$router.push({
          path: "/activity/index",
          query: { id: menuId }
        });
      } else if (type == 1 && code == "5") {
        this.$router.push({
          path: "/meet/index",
          query: { id: menuId }
        });
      } else if (type == 1 && code == "-1") {
        this.$router.push({
          path: "/index",
        });
      }
      this.defaultActive = null
      this.getAppNavigationMenuApi()
    },
    getAppNavigationMenuApi() {
      this.$api
        .getAppNavigationMenuApi({
          channelId: this.$http.channelId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            res.data.map(i => {
              if (i.typeId == this.$route.query.id) {
                this.navTitle = i.menuName
                this.getArticleTypeByParentApi(i.typeId);

              }
            })
          }
        });
    },
    getArticleTypeByParentApi(tId) {
      this.vloading = true
      this.$api.getArticleTypeByParentApi({ typeId: tId }).then(res => {
        this.subMenu = res.data
        this.$nextTick(() => {
          this.activeIndex = tId
        })
        this.vloading = false
        this.$forceUpdate()
      })
      // this.$api
      //   .getOneLevelArticleTypeApi({
      //     channelId: this.$http.channelId,
      //     token: this.token,
      //     isIndexShow: 1,
      //   })
      //   .then((res) => {
      //     if (res.code == 10000) {
      //       this.nav.map(i => {
      //         if (i.menuId == this.$route.query.id) {
      //           i.subMenu = res.data
      //           this.defaultActive = res.data[0].typeId
      //           this.selectAritcleByTypeId(res.data[0].typeId)
      //         }
      //       })
      //       this.stateTab = res.data;
      //     }
      //   });
    },
    // 文章列表查询
    selectAritcleByTypeId(typeId) {
      this.vloading = true
      this.detailList = null
      this.$api
        .getChannelArticleByTypeIdApi({
          pageSize: this.pageSize,
          pageNum: this.page,
          typeId: typeId,
          channelId: this.$http.channelId,
          token: this.token,
          isShowIndex: 1,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.newList = res.data.data;
            this.count = res.data.count;
            this.defaultActive = typeId
            this.vloading = false
          }
        });
    },
    // 分页
    handleCurrentChange(curPage) {
      this.page = curPage;
      this.selectAritcleByTypeId();
    },
    tabClick(index, id) {
      this.newIndex = index;
      if (index == 0) {
        this.selectAritcleByTypeId();
      } else {
        this.selectAritcleByTypeId(id);
      }
    },
    imgClick(v) {
      this.$router.push({
        path: "/news/detail",
        query: { id: v.article_id },
      });
    },
    getArticleInfo(v) {
      this.vloading = true
      this.$api
        .getChannelArticleInfoApi({
          token: this.token,
          articleId: v.article_id,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.detailList = res.data;
            this.vloading = false
          }
        });
    },
    goIndex() {
      this.$router.push({
        path: "/index",
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.allist {
  display: inline-block;
  width: 100%;
}

.shadow {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.radius5 {
  border-radius: 5px;
}

.pad10 {
  padding: 10px;
}

.padding20 {
  padding: 0px 20px;
}

.bgwhite {
  display: inline-block;
  background-color: #fff;
  width: 100%;
}

.borderbot {
  border-bottom: 2px solid #f7f7f7;
}

.borderbot:last-child {
  border: none;
}

.pad20 {
  padding: 20px;
}

.i_i {
  display: inline-block;
  width: 6px;
  height: 7px;
  background: url(../../assets/images/personal/ar.jpg);
  margin-right: 15px;
}

.flexstart {
  display: flex;
  justify-content: flex-start;
}

.msgimg {
  display: inline-block;
  width: 270px;
  margin-right: 25px;
  cursor: pointer;
}

.listimage {
  position: relative;
  width: 270px;
  height: 0;
  padding-bottom: 56.24%;
  overflow: hidden;
  background-position: 50%;
  // background: url(../../assets/images/newsinformation/new.png) no-repeat;
  background-size: cover;
}

.listimage img {
  width: 270px;
}

.title {
  color: #333;
  font-weight: 400;
  margin: 0 10px;
}

.title:hover {
  color: #3ce4bc;
  cursor: pointer;
}

.detail {
  color: #3ce4bc;
  cursor: pointer;
}

.msglist {
  width: 100%;
}

.msglist h4 {
  font-size: 18px;
  margin-bottom: 10px;
  // color: #333;
}

.msglist p {
  font-size: 15px;
  padding: 0 20px;
  line-height: 30px;
  margin: 0 5px;
}

.releasetime {
  text-align: right;
}

.msglist p.releasetime {
  margin-top: 20px;
}

.msglist p.releasetime span {
  margin-left: 5px;
  margin-right: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 5%;
}

.page {
  display: inline-block;
  width: 100%;
  text-align: right;
}

// 开始
.newsty {
  padding: 15px;
}

.flex {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.newli {
  padding: 0 2%;
  cursor: pointer;
}

.active {
  color: #18cca7;
}

// 结束
.w_auto::v-deep .el-tabs__active-bar {
  height: 0px;
}

.w_auto::v-deep .el-tabs__nav-wrap {
  background-color: #fff;
  padding-left: 20px;
  padding-top: 5px;
  height: 50px;
}

.el-menu-item-group::v-deep .el-menu-item-group__title {
  padding: 0 0 0 20px !important;
}

.newsindex {
  width: 100%;

  .container {
    max-width: 1400px;
    margin: 20px auto;

    .el-aside {
      .el-submenu__title {
        font-size: 14px;

      }

      .el-submenu .el-menu-item {
        color: #333;
        height: 45px;
        line-height: 45px;
      }
    }
  }

  .el-aside {
    height: 900px;
  }

  .el-main {
    padding-top: 0px;
    padding-right: 0;
  }

  ::v-deep {
    .el-submenu__title {
      background: rgb(1, 121, 0);
      border-left: 5px solid rgb(143, 197, 62);
      color: #fff;
      font-size: 15px;
    }
  }

  .el-submenu__title * {
    vertical-align: middle;
    width: 100%;
    display: inline-block;
  }
}

.topbg {
  background-image: url('../../assets/images/home/topbg.png');
  background-size: 100% 100%;
  width: 100%;
  height: 365px;
}

.mb50 {
  margin-bottom: 50px;
}

.shadow {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.radius5 {
  border-radius: 5px;
}

.pad10 {
  padding: 10px 5px;
}

.pad20 {
  padding: 20px;
}

.cor4 {
  padding: 20px;
  color: #444;
  text-align: right;
  font-size: 14px;
}

.release-info {
  padding: 20px;
  text-align: right;
}

.borderbot {
  border-bottom: 2px solid #f7f7f7;
}

.allists h2 {
  padding: 40px 0 20px;
  text-align: center;
  font-weight: 400;
  font-size: 21px;
}

.release-info span {
  color: #666;
  font-size: 14px;
}

.release-info span b {
  color: #000;
  font-weight: 400;
}

.newscont {
  text-indent: 20px;
  line-height: 30px;
  font-size: 14px;
}

.newscont p {
  margin-bottom: 15px;
  color: #333;
}

.navbar {
  width: 100%;
  background: #fff;
  height: 50px;
  line-height: 50px;
  padding-left: 20px;
  max-width: 1400px;
  margin: 30px auto;
}

.navtitle {
  color: #007800;
  cursor: pointer;
}

.articleName {
  color: #000
}

@media screen and (max-width: 768px) {
  .el-container {
    display: block;
  }
  .newsindex {
    .el-aside {
      height: auto;
    }
    .el-main {
      width: 100%;
      margin-top: 5.3333vw;
      padding-left: 4vw;
      padding-right: 4vw;
    }
  }
  .allists {
    h2 {
      padding: 8vw 0 5.3333vw;
      font-size: 4.8vw;
    }
  }
}
</style>